import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import title_image from '../images/titles/careers.jpg'


const pageTitle = "Careers";
const pageSlug = "careers";


class BusinessConsulting extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">We want to create a healthy work environment that encourages and empowers our workers to do their best works.</h6>
        </div>
</section>

              
<section id="p_technology_services" className="section static-page page-content">
                  <div className="container">
                      <div className="columns">
                          <div className="column is-12 content-area">
                              
                            <h3>We strive to create a culture that values their work and allows them to better balance their work and their free time.
                              </h3>
                              <span className="break"></span>
                              <p>Human resources in our company has always been the most important resource. Our hiring process is a peak efficiency method, and it begins with our core values. Recruiting people who believe in our philosophy of best practices and solutions of value is our mission.</p>
                              <span className="break"></span>
                              <span className="break"></span>
                              <h4>Inclusion and Diversity </h4>
                              <p>Our company is made stronger by bringing together people from a variety of different backgrounds. Our specialists each bring a distinct style, expertise and personal experience to our organization. This ability to leverage diverse opinions and talents makes it possible for us to challenge expectations and create more insightful solutions to problems that make us better leaders.</p>  
                              <span className="break"></span>
                              <h4>We Respect our Customers and Partners </h4>
                              <p>By working together, we can find ideas and approaches that add value to your processes, helping the company to become more informed, more comfortable, and have a broader, sustainable, tailored solution that can adapt to your situations. We define how we work with our customers, we try hard to find the perfect solution for each customer.</p>
                              <span className="break"></span>
                              <span className="break"></span>
                              <span className="break"></span>
                              <span className="break"></span>
                              <hr />
                              <p>
                                  <a href="">Contact Us</a>
                              </p>

                              

 
                  
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default BusinessConsulting
